'use client'

import React from 'react'; 



const Card = ({cardData}) => {

 if(!Array.isArray(cardData)) {
  return <div>No card data available.</div>
 }


  return (
    <div id="mainContainer" className="flex flex-col  items-center justify-center">
      <div id="containerForWhiteDiv" className="flex flex-col items-center justify-center md:space-y-0 md:flex-row md:items-center md:justify-center md:space-x-20 my-4 md:my-16">
      {cardData.map((card) => (
          <div key={card.id} className=''>
            <div className="w-full md:w-[20rem] text-center text-3xl text-white" >{card.name}</div>
            <div className="w-[20rem] h-[30rem] hover:cursor-pointer rounded-md my-[3rem] md:my-12 ">
              <div id="imgContainer" className=" w-full h-full relative flex justify-center items-center group ">
                <img src={card.src} 
                className="
                rounded-md 
                object-cover 
                w-full 
                h-full 
                transition 
                duration-100 
                group-hover:scale-105 
                group-hover:blur-sm 
                group-hover:-z-10
                group-hover:opacity-50
                " 
                alt="Pic"
                 />
                 <div className={`absolute invisible group-hover:visible group-hover:z-10`}>
                  <div className="flex justify-end">
                  <span className='mr-[1rem] text-white'>{card.size1}</span>
                  <span className='text-white'>{card.size2}</span>
                  </div>
                  <div className={`space-y-[3.5rem]${card.id === 12 ? "idSeven" : "" }${card.id === 7 ? 'idSeven' : ''}${card.id === 9 ? "idSeven" : "" }${card.id === 10 ? "idSeven" : "" }`}>
                    {Array.isArray(card.subMeals) && card.subMeals?.map((meal, index) => (
                     <div key={index} className="flex justify-between space-x-3 text-white">
                     <p>{meal}</p>
                     {card.Price1[index] && <p>{card.Price1[index]}</p>}
                     {card.Price2[index] && <p>{card.Price2[index]}</p>}
                   </div>
                    ))} 
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default Card;