import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";
import Logo from "../Images/Logo.png";

const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openedLink, setOpenedLink] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBurgerClick = () => {
    setOpenedLink(!openedLink);
  };

  return (
    <div>
      <nav
        className={`bg-gray-800 pr-4 w-full rounded-md fixed top-0 z-50 ${
          scrolled
            ? "bg-opacity-0 duration-500"
            : "opacity-100 shadow-2xl transition"
        }`}
      >
        <div className="flex justify-between items-center pr-3">
          <div className="hover:bg-white/10 rounded-lg duration-300">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo"
              className="m-auto"
              width={150}
              height={50}
            />
            </Link>
          </div>

          <button
            className="block md:hidden focus:outline-none"
            onClick={handleBurgerClick}
          >
            <HamburgerIcon />
          </button>
          {/* Render the links for desktop mode */}
          <ul className="hidden md:flex items-center justify-end space-x-8">
            <li className="text-lg text-white hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
              <Link to="/Contact">Contact</Link>
            </li>
            <li className="text-lg text-white hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
              <Link to="/Location">Location</Link>
            </li>
            <li className="text-lg text-white hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
              <Link to="/Menu">Menu</Link>
            </li>
          </ul>
        </div>
      </nav>
      {/* Hamburger menu */}
      <div className="relative">
        <div
          className={`fixed right-0 top-0 w-[50%] h-[100%] bg-gray-800 bg-opacity-80 z-40 transform transition ${
            openedLink ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div className="flex flex-col justify-top items-center mt-[2rem] list-none h-full py-16 px-6 text-white">
            <div className="mb-8 ">
              <li className="text-lg  hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
                <Link to="/Contact" onClick={() => setOpenedLink(false)}>
                  Contact
                </Link>
              </li>
            </div>
            <div className="mb-8">
              <li className="text-lg  hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
                <Link to="/Location" onClick={() => setOpenedLink(false)}>
                  Location
                </Link>
              </li>
            </div>
            <div>
              <li className="text-lg  hover:text-orange-400 duration-300 hover:bg-white/10 p-2 rounded-lg">
                <Link to="/Menu" onClick={() => setOpenedLink(false)}>
                  Menu
                </Link>
              </li>
            </div>
          </div>
        </div>
        <div className="mt-16">{/* Add your content here */}</div>
      </div>
    </div>
  );
};

export default NavBar;

