

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Testimonials = () => {
  return (
    <div className="p-5 text-white">
      <h1 className="text-4xl text-center mb-6">Testimonials</h1>
      <div className="max-w-5xl mx-auto flex flex-col gap-8 md:flex-row md:justify-between">
        <div className="testimonial-container bg-white/10 rounded-xl cursor-pointer p-6">
          <h4 className="text-lg">Kathy Kimzey</h4>
          <p className="text-base leading-7 my-3 font-light opacity-50">
            &quot;a month ago We arrived at 4:00am on a Wednesday and the guy behind the counter couldn’t have been nicer. They had hot food and it was good. Biscuits and gravy and even fried chicken. Thanks for making our travels and stops a good experience.&quot;
          </p>
          <div className="flex items-center justify-center">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-400" />
            ))}
          </div>
        </div>
        <div className="testimonial-container bg-white/10 rounded-xl cursor-pointer p-6">
          <h4 className="text-lg">Rachel Kirby</h4>
          <p className="text-base leading-7 my-3 font-light opacity-50">
            &quot;Clean, friendly, and the food! The food is fantastic and the availability here is amazing!&quot;
          </p>
          <div className="flex items-center justify-center">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-400" />
            ))}
          </div>
        </div>
        <div className="testimonial-container bg-white/10 rounded-xl cursor-pointer p-6">
          <h4 className="text-lg">Sophia</h4>
          <p className="text-base leading-7 my-3 font-light opacity-50">
            &quot;I&apos;m thoroughly impressed by the quality they maintain amidst the hustle of a fuel station. The rich aroma of their blends and the attention to detail in crafting each cup reflects a genuine commitment to excellence. A definite stop for travelers seeking both convenience and exceptional coffee.&quot;
          </p>
          <div className="flex items-center justify-center">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-400" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;