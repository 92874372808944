
let MapAPI = () => {
    return(
        <div className="relative w-full">
            <div className="overflow-hidden rounded-3xl mb-20">
                <iframe
                    className=""
                    width="1000"
                    height="700"
                    loading="lazy"
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD6mJ8PtdwJOTR--lib9xV1OYz7ONW9HA4
                        &q=Dun-N-1,Tupelo+MS">
                    allowFullScreen       
                </iframe>
            </div>
        </div>
    );
}

export default MapAPI;