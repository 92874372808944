import Menu from "./Menu/Menu";


let ContactRoute = () => {
    return (
        <div>
        <Menu></Menu>
        </div>
    );
}

export default ContactRoute;