
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ContactRoute from '../Links/ContactRoute';
import LocationRoute from '../Links/LocationRoute';
import MenuRoute from '../Links/MenuRoute';
import HomePage from '../Links/Home/Home';
import NavBar from '../Components/NavBar/NavBar';

const AppRoutes = () => {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Location" element={<LocationRoute />} />
          <Route path="/Contact" element={<ContactRoute />} />
          <Route path="/Menu" element={<MenuRoute />} />
        </Routes>
      </div>
    </Router>
  );
};

export default AppRoutes;