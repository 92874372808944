import React from "react";

import NavBar from "../../Components/NavBar/NavBar";
import Footer from "../../Components/Footer/Footer";

import MapAPI from "../../Components/Map/Map";


const AboutUs = () => {
    return ( 
        <div>
            <NavBar />
            <div className="overflow-x-hidden">
                <div className="flex justify-center items-center min-h-screen">
                    <div className="max-w-screen-lg mx-auto">
                        <MapAPI />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
 
export default AboutUs;