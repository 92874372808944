

"use client"
import React, {useState} from "react";
import Footer from "../../Components/Footer/Footer";
import NavBar from "../../Components/NavBar/NavBar";
import emailjs from '@emailjs/browser';




const Contact = () => {

    const [formData, setFormData] = useState({
        email : "",
        name : "",
        message: "",
        loading : false,
        show: false,
        alertmessage: ""
    });

    const EmailData = {
        EMAIL_ : "contactdun.n.one@gmail.com",
        PHONE_ : "(662) 269-6582",
        DISCRIPTION : "",
        YOUR_SERVICE_ID : "service_w720r7x",
        YOUR_TEMPLATE_ID : "template_25sj6n1",
        YOUR_PUBLIC_KEY: "h0cvbpkDutypYyHlB",
    }
    const templateParams = {
        from_name : formData.email,
        user_name : formData.name,
        to_name : EmailData.EMAIL_,
        message : formData.message,
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormData({loading :true });

        emailjs.send(EmailData.YOUR_SERVICE_ID,EmailData.YOUR_TEMPLATE_ID,templateParams,EmailData.YOUR_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
            setFormData({
                loading : false,
                alertmessage : "SUCCESS!, message has been sent!",
                show : true,
            });
        },
        (error) => {
            console.log(error.text);
            setFormData({
                alertmessage: `Failed to message, ${error.text}`,
                show : true,
            })
        })

    }
    return (
        <div className="min-h-screen flex flex-col">
            <NavBar />
            <div className="flex-grow flex justify-center items-center">
                <form className="w-full max-w-md p-4" onSubmit={handleSubmit}>
                    <h1 className="text-3xl text-center mb-8 text-white">Contact Us</h1>
                    <div className="mb-4 text-white">
                        <label htmlFor="name" className="block text-sm text-gray-600">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="w-full bg-gray-800 border border-gray-300 rounded px-4 py-2 focus:outline-none focus:bg-black"
                            placeholder="Your Name"
                            value={formData.name || ''}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4 text-white">
                        <label htmlFor="email" className="block text-sm text-gray-600">Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="w-full bg-gray-800 border border-gray-300 rounded px-4 py-2 focus:outline-none focus:bg-black"
                            placeholder="Your Email"
                            value={formData.email || ''}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4 text-white">
                        <label htmlFor="message" className="block text-sm text-gray-600">Message</label>
                        <textarea 
                            name="message"
                            id="message"
                            className="w-full bg-gray-800 border border-gray-300 rounded px-4 py-2 focus:outline-none focus:bg-black"
                            placeholder="Your Message"
                            rows={4}
                            value={formData.message || ''}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600 transition duration-300"
                        disabled={formData.loading}
                    >
                        {formData.loading ? "Sending..." : "Send"}
                    </button>
                    {formData.alertmessage  && <div className="bg-green-200 rounded-lg p-2 text-center mt-4 text-sm text-gray-600">{formData.alertmessage }</div>}
                </form>
            </div>
            <Footer />
        </div>
    );
}
 
export default Contact;