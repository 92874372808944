



const FrontDesign = () => {
    return (
      <div>
        {/* Desktop Version */}
        <div className="hidden md:block text-white">
          <div className="bg-white/10 pl-5 mx-auto mt-3 rounded-lg">
            <div className="flex justify-between">
              <div className="mx-auto my-auto text-xl p-3">
                From expertly crafted blends to cozy ambiance, we invite you to savor the finest coffee selections and more at our welcoming space. Join us on a journey of taste and comfort – your perfect coffee moment awaits!
              </div>
              <div className="max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1511920170033-f8396924c348?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
              </div>
            </div>
          </div>
          <div className="bg-white/10 pr-5 max-w-7xl mx-auto mt-3 rounded-lg">
            <div className="flex justify-center">
              <div className="mx-auto my-auto text-sm p-3">
                Indulge your senses in a symphony of flavors as you explore our diverse coffee menu, featuring artisanal brews from around the world.
              </div>
              <div className="max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1507133750040-4a8f57021571?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D" alt="" />
              </div>
              <div className="mx-auto my-auto text-sm p-3">
                At our coffee haven, we take pride in not only serving exceptional coffee but also creating a warm and inviting atmosphere for you to unwind.
              </div>
            </div>
          </div>
          <div className="bg-white/10 pr-5 mx-auto mt-3 rounded-lg">
            <div className="flex justify-between">
              <div className="max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1512568400610-62da28bc8a13?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D" alt="" />
              </div>
              <div className="mx-auto text-sm my-auto p-3">
                Whether you&apos;re a connoisseur seeking the perfect espresso or a casual coffee lover in search of a cozy spot, our passionate team is here to make every visit memorable. Join us, and let the aroma of freshly ground beans and the warmth of our hospitality envelop you in a truly unique coffee experience.
              </div>
            </div>
          </div>
        </div>
        
        {/* Mobile Version */}
        <div className="block md:hidden text-white">
          <div className="bg-white/10 pl-5 mx-auto mt-3 rounded-lg">
            <div className="flex flex-col">
              <div className="mx-auto my-auto text-xl p-3">
                From expertly crafted blends to cozy ambiance, we invite you to savor the finest coffee selections and more at our welcoming space. Join us on a journey of taste and comfort – your perfect coffee moment awaits!
              </div>
              <div className="mx-auto my-auto max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1511920170033-f8396924c348?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="" />
              </div>
            </div>
          </div>
          <div className="bg-white/10 pr-5 max-w-7xl mx-auto mt-3 rounded-lg">
            <div className="flex flex-col">
              <div className="mx-auto my-auto text-sm p-3">
                Indulge your senses in a symphony of flavors as you explore our diverse coffee menu, featuring artisanal brews from around the world.
              </div>
              <div className="mx-auto my-auto max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1507133750040-4a8f57021571?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fHw%3D" alt="" />
              </div>
              <div className="mx-auto my-auto text-sm p-3">
                At our coffee haven, we take pride in not only serving exceptional coffee but also creating a warm and inviting atmosphere for you to unwind.
              </div>
            </div>
          </div>
          <div className="bg-white/10 pr-5 mx-auto mt-3 rounded-lg">
            <div className="flex flex-col">
              <div className="mx-auto my-auto max-w-xs">
                <img className="rounded-lg" src="https://images.unsplash.com/photo-1512568400610-62da28bc8a13?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDF8fHxlbnwwfHx8fHw%3D" alt="" />
              </div>
              <div className="mx-auto my-auto text-sm p-3">
                Whether you&apos;re a connoisseur seeking the perfect espresso or a casual coffee lover in search of a cozy spot, our passionate team is here to make every visit memorable. Join us, and let the aroma of freshly ground beans and the warmth of our hospitality envelop you in a truly unique coffee experience.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
    
  export default FrontDesign;