

import SmokeySides from '../../Components/Images/MenuPics/SmokeySides.jpg';
import BrisketSandwitch from '../../Components/Images/MenuPics/BrisketSandwitch.jpg';
import FamilyMeals from '../../Components/Images/MenuPics/FamilyMeals.jpg';
import BulkedMeat from '../../Components/Images/MenuPics/BulkedMeat.jpg';
import BBQ from '../../Components/Images/MenuPics/BBQ.jpg';
import CHS from '../../Components/Images/MenuPics/ChickenStrips.jpg';
import FriedShrimp from '../../Components/Images/MenuPics/FriedShrimp.jpg';
import Singles from '../../Components/Images/MenuPics/HamburgerSteaks.jpg';
import Biscuits from '../../Components/Images/MenuPics/Biscuits.jpg';
import BExtras from '../../Components/Images/MenuPics/BExtras.jpg';
import BreakfastPlate from '../../Components/Images/MenuPics/BreakfastPlate.jpg';
import Wings from '../../Components/Images/MenuPics/Wings.jpg';

import Card from '../../Components/Card/Card';
import NavBar from '../../Components/NavBar/NavBar';
import Footer from '../../Components/Footer/Footer';




const Menu = ({cardData}) =>{


    const cardData1 = [
        { 
          id: 1, 
          src: SmokeySides, 
          name: "Smokey Sides" , 
          subMeals: ["Loaded Baked Beans -->","Fresh Potato Salad -->","Mamm's Coleslaw-->","Smokey Mac N Chees -->","Baked Potato Casserole-->"],
          size1 : "Small",
          size2 : "Large",
          Price1 : ["$2.49","$2.49","$2.49","$2.49","$2.49"],
          Price2 : ["$4.99","$4.98","$4.99","$4.99","$4.99"]
        },
        { 
          id: 2, 
          src: BrisketSandwitch, 
          name: "BBQ Baskets",
          subMeals: ["Pork Sandwich -->","Leg Quarter -->","Brisket Sandwich-->","Ribs 1/2 -->"],
          Price1 : [],
          Price2 : ["$7.99","$7.99","$8.99","$10.99"]
        },
        { 
          id: 3, 
          src: FamilyMeals, 
          name: "Family Packs",
          subMeals: ["2 Ibs Pork-8 buns-2 Large Sides Big  -->","4Ibs Pork-12 Buns-3 Large Sides -->"],
          Price1 : [],
          Price2 : ["$29.99","$59.99"]
        },
      ]
    const  cardData2 = [
        { 
          id: 4, 
          src: BulkedMeat, 
          name: "Bulk Smoked Meats",
          subMeals: ["Pulled Pork Ib-->","Leg Quarter -->","Chopped Brisket Ib-->","Ribs Full Side-->","Ribs 1/2 side-->","Rib Tips PerOrder -->"],
          Price1 : [],
          Price2 : ["$10.99","$3.99","$15.99","$19.99","$15.99","6.99"]
         },
        { 
          id: 5, 
          src: BBQ, 
          name: "BBQ Plates",
          subMeals: ["Pulled Pork -->"," Leg Quarter-->","Chopped Brisket-->","Ribs Full Side -->","Ribs 1/2 Side-->","Rib Tips -->"],
          Price1 : [],
          Price2 : ["$9.99","$8.99","$10.99","$19.99","$12.99","$8.99"]
         },
        { 
          id: 6, 
          src: CHS, 
          name: "Plate Lunches",
          subMeals: ["Pork Chop -->","Fried Chicken -->","Chicken Strips-->","Hamburger Steak -->","Country Fried Steak-->","2 PC Fried Fish -->"],
          Price1 : [],
          Price2 : ["$8.99","$8.99","$8.99","$8.99","$8.99","$8.99"] 
        },
      ]
    const  cardData3 = [
        { 
          id: 7, 
          src: FriedShrimp, 
          name: "Extras",
          subMeals: ["Corn Nuggets -->",
          "Tater Babies -->",
          "Chedder Peppers-->",
          "Cheese Stickes -->",
          "Crispito Plain or Buffalo-->",
          "Fried Shrip (6) -->",
          "Fried Shrimp (12)-->",
          "Egg Roll -->",
          "Corn Dog-->",
          "Burrito-->",
          "Pizza Stick-->",
          "Chicken On A Stick -->"],
          Price1 : [],
          Price2 : ["$3.99","$2.99","$3.99","$3.99","$2.59","$4.99","$8.99","$2.29","$2.29","$2.29","$2.29","$5.99"],
        },
        { id: 8, 
          src: Singles, 
          name: "Singles",
          subMeals: ["Hamburger Steak -->","Sides -->","Spagetti-->","Fish -->"],
          Price1 : [],
          Price2 : ["$4.99","$1.99","$4.99","$4.99"]
        },
        { id: 9, 
          src: Biscuits, 
          name: "Biscuits",
          subMeals: ["Sausage -->","Smoked Sausage -->","Bologna-->","Chicken -->","Steak-->","Fried Tenderloin-->","Bacon-->","Ham-->","Egg-->","Gravy-->","Plain-->"],
          Price1 : [],
          Price2 : ["$1.99","$3.29","$3.29","$3.29","$3.29","3.29","3.29","3.29","1.89","2.39","1.29"] 
        },
      ]
    const cardData4 = [
        { id: 10, 
          src: BExtras, 
          name: "Biscuit Extra's",
          subMeals: ["Smoked Sasage -->","Sausage Patty -->","Steak Patty-->","Ham -->","Fried Pork Loin-->","Chicken Strip-->","Bacon-->","Bologna-->","Gravy-->","Egg or Cheese-->"],
          Price1 : [],
          Price2 : ["$2.49","$1.59","$2.49","$2.49","$2.49","1.99","0.89","2.49","1.29","0.69"]
        },
        { id: 11, 
          src: BreakfastPlate, 
          name: "BreakFast Plate",
          subMeals: ["With Meat, Biscuit Eggs, Hasbrowns Grits & Gravy-->"],
          Price1 : [],
          Price2 : ["$6.99"] 
        },
        { 
          id: 12, 
          src: Wings, 
          name: "Chicken Specials & Wings",
          subMeals: ["3 Legs -->","3 Wings -->","Breast-->","White Box -->","Dark Box-->","8pc Box 2 Breaks-Legs thights wings","16pc Box 4 Breast Legs thights wings"],
          Price1 : [],
          Price2 : ["$6.99","$7.98","$5.49","$7.99","$6.99","16.99","29.99"]
        },
      ]

    return (
            <div>
                <div>
                    <NavBar></NavBar>
                </div>
                <div className="my-[6.5rem] text-[5rem] flex justify-center ">
                    <p className="border-b-[4px] text-white">Menu</p>
                </div>
                <div>  
                <Card cardData={cardData1}></Card>
                <Card cardData={cardData2}></Card>
                <Card cardData={cardData3}></Card>
                <Card cardData={cardData4}></Card>
                </div>
                <Footer/>
            </div>
        
    );
}


 
export default Menu;