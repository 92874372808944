import Location from "./Location/Location"


let ContactRoute = () => {
    return (
        <div>
            <Location></Location>
        </div>
    );
}

export default ContactRoute;