
import Contact from './Contact/Contact'


let ContactRoute = () => {
    return (
        <div>
            <Contact></Contact>
        </div>
    );
}

export default ContactRoute;