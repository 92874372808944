"use client"

import Video from "../../Components/Images/DunN1.mp4"
import FrontDesign from "../../Components/FrontHomeDesign/FrontHomeDesign";
import Testimonials from "../../Components/Testimonials/Testimonials";
import Footer from "../../Components/Footer/Footer";

const HomePage = () => {

    return (
        <div className="relative">
          <div className="relative w-full h-screen overflow-hidden">
             <video autoPlay playsinline loop muted className="w-full h-full object-cover">
               <source src={Video} type="video/mp4" />
             </video>
             
          </div>
          <div className="text-center font-custom text-white">
            <h1 className="p-5 text-5xl text-blue-400 font-bold">
              D<span className="text-orange-400">U</span>N<span className="text-blue-400">-</span>N<span className="text-orange-400">-</span>1
            </h1>
            <div className="bg-white/10 rounded-xl p-10 max-w-2xl mx-auto">
              <p className="tracking-widest ml-2">
                We combine quality coffee with the ease of a gas station. Our brews energize your day while our friendly ambiance turns a quick stop into a cozy break. Enjoy exceptional service and a welcoming atmosphere—an inviting space where convenience meets a warm, friendly welcome for both coffee lovers and travelers alike.
              </p>
            </div>
          </div>
            <div className="text-center uppercase italic font-custom">
                <div className="block md:hidden">
                    {/* Mobile Responsive Components */}
                   <FrontDesign></FrontDesign>
                   <Testimonials></Testimonials>
                </div>
                <div className="hidden md:block">
                    {/* Desktop Version */}
                    <div>
                    <FrontDesign></FrontDesign>
                    </div>
                    <div>
                    <Testimonials></Testimonials>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
};

export default HomePage;